import { Routes } from '@angular/router';
import { loggedInGuard } from './permissions/logged-in.guard';

export const customerRoutes: Routes = [
	{
		path: 'transpromo',
		loadChildren: () => import('./transpromo/transpromo.module').then(m => m.TranspromoModule),
		canActivate: [
			loggedInGuard,
		],
	},
];
