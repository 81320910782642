import configuration from 'globalConfiguration';
import featureHelper from 'helper/featureHelper';
import textHelper from 'helper/textHelper';
import selectorConfiguration from '../../legacy/customer/comni/selectorConfiguration';
import { legacyRequire } from '../../legacy-manager';

function DependencyManager() {
	this.configuration = configuration;
	/**
	 * @type {import('./selectorConfiguration').SelectorConfiguration}
	 */

	this.selectorConfiguration = selectorConfiguration;

	/**
	 * @type {{[key:string]:boolean}}
	 *
	 */

	this.loadedPlugins = {};

	/**
	 * @type {JQuery<Document>|string}
	 */
	this.pageContent = '';
	this.selectorArray = [];
	this.matchedSelectors = [];

	this.warningMessage = 'warning.leaving.application';

	$.when(featureHelper.hasFeature('useErrorTooltips'), textHelper.initTexts([this.warningMessage]))
		.then((hasFeature, messages) => {
			this.configuration.AjaxRotator.useErrorTooltips = hasFeature;
			this.warningMessage = messages[this.warningMessage];
			this.init();
		});
}

DependencyManager.prototype = {
	init: function () {
		this.setEventListeners();
		this.collectSelectors();
		this.onStartup();
		// Warning-Dialog only on pages with sitenav
		if ($('#nav').length > 0) {
			this.setWindowBeforeUnload();
		}
	},

	setWindowBeforeUnload: function () {
		window.onbeforeunload = () => this.warningMessage;
	},

	removeWindowBeforeUnload: function () {
		window.onbeforeunload = () => {
		};
	},

	setSelectorConfiguration: function (config) {
		this.selectorConfiguration = config;
	},

	setConfiguration: function (config) {
		this.configuration = config;
	},

	setEventListeners: function () {
		$(document)
			.ready(this.onPageLoaded.bind(this));
		$(document)
			.on('ajaxPageLoaded sliderReloaded', this.onPageLoaded.bind(this));
	},

	onPageLoaded: function (e, html) {
		this.pageContent = $(html);
		this.searchForSelectors();
		this.resolveConjunctions();
	},

	/**
	 * Make a complete List of all selectors to watch out for
	 */

	collectSelectors: function () {
		$.each(this.selectorConfiguration, (i) => {
			if (this.selectorArray[i] === undefined) {
				this.selectorArray.push(i);
			}
		});
	},

	/**
	 * Seek the pageContent for the selectors
	 */
	searchForSelectors: function () {
		this.matchedSelectors = [];
		for (let i = 0; i < this.selectorArray.length; i++) {
			if (this.pageContent.find(this.selectorArray[i]).length > 0) {
				this.matchedSelectors.push(this.selectorArray[i]);
			}
		}
	},

	/**
	 * Resolve the plugin <-> selector conjunctions
	 */
	resolveConjunctions: function (additionalContext?) {
		for (let i = 0; i < this.matchedSelectors.length; i++) {
			let selector = this.matchedSelectors[i];
			const selectorConf = this.selectorConfiguration[selector];

			$.each(selectorConf, (i, el) => {
				let name,
					conf,
					context,
					confName;
				name = el.name;
				confName = el.hasOwnProperty('configurationName') ? el.configurationName : name;
				conf = $.extend({}, this.configuration.global, this.configuration[name], this.configuration[confName], el.configuration);

				/**
				 * @type {keyof import('../type/define').ImportMap}
				 */
					// @ts-ignore
				let path = el.requirePath;
				context = el.initializationContext ? el.initializationContext : selector;
				this.createPluginInstance(name, conf, context, path, additionalContext, el);
			});
		}
	},
	/**
	 *
	 * @param {string} name
	 * @param {*} conf
	 * @param {*} context
	 * @param {keyof import('../type/define').ImportMap} requirePath
	 * @param {*} additionalContext
	 * @param {import('./selectorConfiguration').SelectorConfig} el
	 */
	createPluginInstance: function (name, conf, context, requirePath, additionalContext, el) {
		let deactivatedPlugins = localStorage.getItem('deactivated_js_plugins');
		if (deactivatedPlugins && deactivatedPlugins.indexOf(name) !== -1) { return; }

		const onload = (plugin?) => {
			this.loadedPlugins[name] = true;
			if ($.fn[name]) {
				if (additionalContext) {
					($(additionalContext + ' ' + context)[name] as any)(conf);
				} else {
					$(context)[name](conf);
				}
			} else {
				if (typeof plugin === 'function') {
					plugin();
				}
			}
		};

		if (!requirePath || (el && el.loadOnlyOnce && this.loadedPlugins[name])) {
			onload();
			return;
		}
		/**
		 * @type {keyof import('../type/define').ImportMap}
		 */
			// @ts-ignore when all config paths are added revert
		const path = requirePath.toString();
		legacyRequire(path, onload);
	},

	onStartup: function () {
		let conf = this.selectorConfiguration.startup;

		$.each(conf, (i, el) => {
			let name,
				conf,
				path,
				context,
				confName;

			name = el.name;
			confName = el.hasOwnProperty('configurationName') ? el.configurationName : name;
			conf = $.extend({}, configuration.global, configuration[confName], el.configuration);
			path = el.requirePath;
			context = el.initializationContext;
			this.createPluginInstance(name, conf, context, path);
		});

		this.pageContent = $(document);

		this.searchForSelectors();
		this.resolveConjunctions();
	},

	initOnElement: function (element) {
		let additionalContext = element.prop('tagName');
		if (typeof element.attr('id') != 'undefined') {
			additionalContext += '#' + element.attr('id');
		}
		if (typeof element.attr('class') != 'undefined') {
			let tmpClass = element.attr('class');
			additionalContext += '.' + tmpClass.replace(' ', '.');
		}
		this.pageContent = element;
		this.searchForSelectors();
		this.resolveConjunctions(additionalContext);
	},

};

export default new DependencyManager();
